.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-ellipsis-second-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fix-overflow-ellipsis {
  min-width: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.large {
  font-size: 120%;
}

.semi-small {
  font-size: 90%;
}

.small {
  font-size: 80%;
}

.tiny {
  font-size: 70%;
}

.inline-block {
  display: inline-block;
}

/* distinguish colors https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/ */

.bgcolor-red {
  background-color: #e6194b;
}

.bgcolor-green {
  background-color: #3cb44b;
}

.bgcolor-yellow {
  background-color: #ffe119;
}

.bgcolor-blue {
  background-color: #4363d8;
}

.bgcolor-orange {
  background-color: #f58231;
}

.bgcolor-purple {
  background-color: #911eb4;
}

.bgcolor-cyan {
  background-color: #42d4f4;
}

.bgcolor-magenta {
  background-color: #f032e6;
}

.bgcolor-lime {
  background-color: #bcf60c;
}

.bgcolor-pink {
  background-color: #fabebe;
}

.bgcolor-teal {
  background-color: #008080;
}

.bgcolor-lavender {
  background-color: #e6beff;
}

.bgcolor-brown {
  background-color: #9a6324;
}

.bgcolor-beige {
  background-color: #fffac8;
}

.bgcolor-maroon {
  background-color: #800000;
}

.bgcolor-mint {
  background-color: #aaffc3;
}

.bgcolor-olive {
  background-color: #808000;
}

.bgcolor-apricot {
  background-color: #ffd8b1;
}

.bgcolor-navy {
  background-color: #000075;
}

.bgcolor-gray {
  background-color: #808080;
}

.bgcolor-white {
  background-color: #ffffff;
}

.bgcolor-black {
  background-color: #000000;
}

/* https://www.sitepoint.com/using-helper-classes-dry-scale-css/ */

.keep-word {
  word-break: keep-all;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.align-middle {
  vertical-align: middle;
}

/* https://github.com/rishabh-rajgarhia/bootstrap-4-utilities/blob/master/dist/bootstrap-4-utilities.css */
/*-- float utilities --*/
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.float-none {
  float: none !important;
}

/*-- text utilities --*/
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: bold;
}

/*-- spacing utilities --*/
.m-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}