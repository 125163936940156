.login-background {
  height: 100vh;
  background: url('/img/login-background.jpg') center / cover no-repeat;
}

.shadow-top {
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
}

.shadow-left {
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
}

.shadow-right {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
}

.shadow-bottom {
  box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
}

.main-menu {
  list-style: none;
  font-size: 32px;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.main-menu a {
  transition: 0.3s;
  color: #495057;
}

.main-menu a,
.main-menu a:hover {
  padding: 10px 0;
  display: block;
  margin: 0;
}

.main-menu a:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.125);
}

.main-menu a.active {
  color: #000000;
  background-color: rgba(0, 0, 0, 0.15);
}

.search-input-address {
  width: 150px;
}

.logout-btn {
  color: #adb5bd;
  position: absolute;
  bottom: 0;
  width: 60px;
}

/* layout start */

.layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: block;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 60px;
}

.bb-1 {
  border-bottom: 1px solid #dee2e6 !important;
}

.search {
  display: block;
  /* flex: auto;
  height: 55px; */
}

.search-input {
  /* border-radius: 25px; */
  /* border-left: none; */
  /* border-right: none; */
}

.main {
  display: block;
  flex: auto;
  min-height: 0;
  overflow-y: auto;
}

.footer {
  display: block;
}

.layout,
.layout * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.header,
.footer {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}


/* layout end */

.header h1 {
  font-size: 1.2rem;
  margin: 0;
}

.header-btn {
  height: 60px;
  font-size: 1.4rem;
  color: #212529;
}


.header-col {
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.field-history thead tr {
  height: 60px;
}

.field-history thead tr th:first-child {
  font-size: 1.2rem !important;
  padding: 12px;
  text-align: center;
}

.field-history td {
  padding: 12px !important;
  cursor: pointer;
}

.field-history td:hover {
  background-color: rgba(0, 0, 0, 0.125) !important;
}

.image-list li,
.memo-list li,
.field-list li {
  transition: 0.3s;
  border-bottom: 1px solid #dee2e6 !important;
}

.image-list li:hover,
.memo-list li:hover,
.field-list li:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}

.image-list li.active,
.memo-list li.active,
.field-list li.active {
  background-color: rgba(0, 0, 0, 0.06);
  /* border-right: 2px solid #27ae60 !important; */
  /* padding-left: 10px; */
}

.memo-list li.active .content {
  white-space: pre-wrap;
}

.circle {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  top: 1px;
  border-radius: 50%;
  margin-right: 4px;
  position: relative;
  -webkit-print-color-adjust: exact;
}

.crop-rotation-detail {
  list-style: none;
  margin: 0;
  padding: 0;
}

.crop-rotation-detail strong {
  display: inline-block;
  width: 60px;
  padding-right: 10px;
  text-align: right;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.color-secondary {
  color: #868e96;
}

.memo-image-small .ant-image-img {
  object-fit: cover;
  width: 75px;
  height: 75px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.recorder {
  display: none;
}

/* svg.field {
  background-color: #f8f9fa;
} */